import { useQuery } from '@tanstack/react-query'
import { useNavigate, useParams } from '@tanstack/react-router'

import { Scoring } from '@/components/scoring'
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from '@/components/ui/accordion'
import { Button } from '@/components/ui/button'
import { Skeleton } from '@/components/ui/skeleton'
import { applicationsApplicantInfoQuery } from '@/hooks/use-applications-queries'
import { useUpdateApplicationJobPreferences } from '@/modules/application/presentation/hooks/useApplications'
import { ApplicantApplicationsHistory } from '@/shared/presentation/applicant/ApplicantApplicationsHistory'
import { ApplicantJobPreferences } from '@/shared/presentation/applicant/ApplicantJobPreferences'
import { ApplicantResume } from '@/shared/presentation/applicant/ApplicantResume'

import { Applicant } from './applicant'
import { QualificationsData } from './qualifications-data'

interface ApplicationAccordionProps {
  applicationId: string
}

export function ApplicationAccordion({ applicationId }: ApplicationAccordionProps) {
  const { data, isLoading } = useQuery(applicationsApplicantInfoQuery(applicationId))
  const { jobId } = useParams({ from: '/_authenticated/jobs_/$jobId/applications/$applicationId' })
  const mutation = useUpdateApplicationJobPreferences(applicationId)

  const navigate = useNavigate({ from: '/jobs/$jobId' })

  if (isLoading) return <ApplicationAccordionSkeleton />

  if (!data) return null

  const EmptyStateAction = (
    <Button
      onClick={async () => {
        await navigate({
          params: { applicationId, jobId },
          search: { view: 'files' },

          to: '/jobs/$jobId/applications/$applicationId',
        })
      }}
    >
      Upload a resume
    </Button>
  )

  return (
    <Accordion className="space-y-4" type="multiple">
      <AccordionItem key="resume" value="resume">
        <AccordionTrigger className="bg-muted">Resume / CV</AccordionTrigger>
        <AccordionContent>
          <ApplicantResume
            emptyStateAction={EmptyStateAction}
            emptyStateSubtitle="You can upload a resume in files tab"
            url={data?.resume}
          />
        </AccordionContent>
      </AccordionItem>
      <AccordionItem key="applicant" value="applicant">
        <AccordionTrigger className="bg-muted">Manage personal data</AccordionTrigger>
        <AccordionContent>
          <Applicant
            data={{ ...data.applicant, applicationSource: data?.source, applicationTypeOfSource: data?.typeOfSource }}
          />
        </AccordionContent>
      </AccordionItem>
      <AccordionItem key="jobPreferences" value="jobPreferences">
        <AccordionTrigger className="bg-muted">Job preferences</AccordionTrigger>
        <AccordionContent>
          <ApplicantJobPreferences mutation={mutation} preferences={data.jobPreferences} />
        </AccordionContent>
      </AccordionItem>
      <AccordionItem key="qualificationsData" value="qualificationsData">
        <AccordionTrigger className="bg-muted">
          Qualifications data
          {data?.qualificationsData.matchScore && (
            <div className="ml-auto mr-2">
              <Scoring max={5} value={data.qualificationsData.matchScore} />
            </div>
          )}
        </AccordionTrigger>
        <AccordionContent>
          <QualificationsData data={data.qualificationsData} />
        </AccordionContent>
      </AccordionItem>
      <AccordionItem key="historyOfApplications" value="historyOfApplications">
        <AccordionTrigger className="bg-muted">History of applications</AccordionTrigger>
        <AccordionContent>
          <ApplicantApplicationsHistory applications={data.historyOfApplications} />
        </AccordionContent>
      </AccordionItem>
    </Accordion>
  )
}

function ApplicationAccordionSkeleton() {
  return (
    <div className="flex flex-col gap-y-4">
      <Skeleton className="h-14 w-full" />
      <Skeleton className="h-14 w-full" />
      <Skeleton className="h-14 w-full" />
      <Skeleton className="h-14 w-full" />
    </div>
  )
}
