import { z } from 'zod'

import { AvailabilityPeriodLong } from '../enums/availability/availability-period.enum'
import { AvailabilityStatus } from '../enums/availability/availability-status.enum'

const expectedSalarySchema = z
  .object({
    currency: z.string().nullable().optional(),
    enabled: z.boolean().optional(),
    max: z.number().int().min(1, 'Max must be greater than 0').nullable().optional(),
    min: z.number().int().min(1, 'Min must be greater than 0').nullable().optional(),
    period: z.string().nullable().optional(),
  })
  .superRefine(({ currency, enabled, max, min, period }, ctx) => {
    if (enabled === true) {
      if (min === null || min === undefined) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'Min is required.',
          path: ['min'],
        })
      }
      if (max === null || max === undefined) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'Max is required.',
          path: ['max'],
        })
      }
      if (currency === null || currency === undefined) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'Currency is required.',
          path: ['currency'],
        })
      }
      if (period === null || period === undefined) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'Period is required.',
          path: ['period'],
        })
      }
      if (min !== null && min !== undefined && max !== null && max !== undefined) {
        if (min > max) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: 'Min must be less than max.',
            path: ['min'],
          })
        }
        if (max < min) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: 'Max must be greater than min.',
            path: ['max'],
          })
        }
      }
    }
  })

const salaryExpectationSchema = z.object({
  apprenticeship: expectedSalarySchema.nullable(),
  fixedTerm: expectedSalarySchema.nullable(),
  freelance: expectedSalarySchema.nullable(),
  internship: expectedSalarySchema.nullable(),
  permanent: expectedSalarySchema.nullable(),
})

export const updateJobPreferencesSchema = z
  .object({
    availability: z.date().nullable().optional(),
    availabilityDelay: z.number().int().max(90).nullable().optional(),
    availabilityPeriod: z.nativeEnum(AvailabilityPeriodLong).nullable().optional(),
    availabilityStatus: z.nativeEnum(AvailabilityStatus),
    salaryExpectations: salaryExpectationSchema,
  })
  .superRefine(({ availability, availabilityDelay, availabilityPeriod, availabilityStatus }, ctx) => {
    if (availabilityStatus === AvailabilityStatus.KNOW_DATE) {
      if (!availability) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'Date is required.',
          path: ['availability'],
        })
      }
      return
    }

    if (availabilityStatus === AvailabilityStatus.NOTICE_PERIOD) {
      if (!availabilityPeriod) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'Period is required.',
          path: ['availabilityPeriod'],
        })
      }

      if (availabilityDelay === 0) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'Delay must be greater than 0.',
          path: ['availabilityDelay'],
        })
      }
    }
  })

export type UpdateJobPreferencesData = z.infer<typeof updateJobPreferencesSchema>
