import { createFileRoute } from '@tanstack/react-router'

import { PageMenu } from '@/components/page-menu'
import { userQueryOptions } from '@/modules/user/presentation/hooks/useUsers'

export const Route = createFileRoute('/_authenticated/applicants/$applicantId')({
  beforeLoad: () => {
    return {
      meta: {
        title: 'Applicant : ',
      },
    }
  },
  loader: async ({ context: { queryClient }, params: { applicantId } }) => {
    await queryClient.ensureQueryData(userQueryOptions(applicantId))
  },
  pendingComponent: () => (
    <>
      <PageMenu>
        <div className="grid w-full grid-cols-3">
          <div className="h-9 w-28" />
          <div className="h-9 w-full" />
          <div className="ml-auto h-9 w-28" />
        </div>
      </PageMenu>
    </>
  ),
})
