import { useMutation } from '@tanstack/react-query'

import { ApiGateway } from '@/modules/api/api.gateway'
import { ApplicationRepository } from '@/modules/application/adapter/application.repository'
import { ApplicationUseCase } from '@/modules/application/application/application.use-case'
import { ApplicationAssign } from '@/modules/application/domain/entities/application.entity'
import { CreateApplicationDto } from '@/modules/application/domain/schemas/create.schema'
import { UpdateJobPreferencesData } from '@/shared/domain/schemas/update-job-preferences.schema'

const apiGateway = new ApiGateway()
const applicationRepository = new ApplicationRepository(apiGateway)
const applicationUseCase = new ApplicationUseCase(applicationRepository)

const useApplicationAssign = () => {
  return useMutation({
    mutationFn: (data: ApplicationAssign) => applicationUseCase.assign(data),
  })
}

const useApplicationCreate = () => {
  return useMutation({
    mutationFn: (data: CreateApplicationDto) => applicationUseCase.create(data),
  })
}

const useUpdateApplicationJobPreferences = (applicantId: string) => {
  return useMutation({
    mutationFn: (data: UpdateJobPreferencesData) => applicationUseCase.updateJobPreferences(applicantId, data),
  })
}

export { useApplicationAssign, useApplicationCreate, useUpdateApplicationJobPreferences }
