import { zodResolver } from '@hookform/resolvers/zod'
import { useNavigate, useSearch } from '@tanstack/react-router'
import axios from 'axios'
import { useForm } from 'react-hook-form'

import { CustomFormField, FormFieldType } from '@/components/custom-form-field'
import { Button } from '@/components/ui/button'
import { Form } from '@/components/ui/form'
import { useAuth } from '@/hooks/use-auth'
import { LoginValues, loginSchema } from '@/schemas/auth'

export function LoginForm() {
  const navigate = useNavigate()
  const search = useSearch({ from: '/auth/login' })
  const { login } = useAuth()
  const form = useForm<LoginValues>({
    defaultValues: {
      email: '',
      password: '',
    },
    resolver: zodResolver(loginSchema),
  })

  const onSubmit = async (values: LoginValues) => {
    try {
      await login(values)
      await navigate({ to: search.redirect })
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error.response?.status === 404) {
          form.setError('email', {
            message: 'Invalid email or password',
            type: 'manual',
          })
          form.setError('password', {
            message: 'Invalid email or password',
            type: 'manual',
          })
        }
      }
    }
  }

  return (
    <Form {...form}>
      <form className="flex flex-col gap-y-4" onSubmit={form.handleSubmit(onSubmit)}>
        <CustomFormField
          control={form.control}
          fieldType={FormFieldType.INPUT}
          label="Email"
          name="email"
          placeholder="aurelien@aventa.fr"
          type="email"
        />
        <CustomFormField
          control={form.control}
          fieldType={FormFieldType.INPUT}
          label="Password"
          name="password"
          placeholder="******"
          type="password"
        />
        <Button isLoading={form.formState.isSubmitting} size="lg" type="submit">
          Login
        </Button>
      </form>
    </Form>
  )
}
