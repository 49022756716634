import {
  AlertDialog,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from '@/components/ui/alert-dialog'
import { useFormNavigation } from '@/hooks/useFormNavigation'
import { useFormNavigationStore } from '@/store/formNavigationStore'

import { Button } from './ui/button'

export const NavigationModal = () => {
  const { getDirtyFormsCount, modalOpen, resetAllForms, setModalOpen } = useFormNavigationStore()
  const blocker = useFormNavigation()

  const handleConfirmLeave = () => {
    resetAllForms()
    setModalOpen(false)

    if (blocker.status === 'blocked') {
      blocker.proceed()
    }
  }

  const handleCancelLeave = () => {
    setModalOpen(false)

    if (blocker.status === 'blocked') {
      blocker.reset()
    }
  }

  return (
    <AlertDialog onOpenChange={setModalOpen} open={modalOpen}>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>Unsaved changes</AlertDialogTitle>
          <AlertDialogDescription>
            You have {getDirtyFormsCount()} form{getDirtyFormsCount() > 1 && '(s)'} with unsaved changes. Are you sure
            you want to leave this page?
          </AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel onClick={handleCancelLeave}>Stay on page</AlertDialogCancel>
          <Button onClick={handleConfirmLeave} variant="destructive">
            Quit without saving
          </Button>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  )
}
