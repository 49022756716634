import { ApplicationAssign } from '@/modules/application/domain/entities/application.entity'
import { IApplicationRepository } from '@/modules/application/domain/repositories/application.repository'
import { CreateApplicationDto } from '@/modules/application/domain/schemas/create.schema'
import { IdResponseDto } from '@/shared/domain/entities/common.entity'
import { UpdateJobPreferencesData } from '@/shared/domain/schemas/update-job-preferences.schema'
import { JobPreferences } from '@/types/application/applicant-info'

export class ApplicationUseCase {
  constructor(private applicationRepository: IApplicationRepository) {}

  async assign(data: ApplicationAssign): Promise<IdResponseDto> {
    return this.applicationRepository.assign(data)
  }

  async create(data: CreateApplicationDto): Promise<IdResponseDto> {
    return this.applicationRepository.create(data)
  }

  async updateJobPreferences(applicantId: string, data: UpdateJobPreferencesData): Promise<JobPreferences> {
    return this.applicationRepository.updateJobPreferences(applicantId, data)
  }
}
