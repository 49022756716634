/* eslint-disable */

// @ts-nocheck

// noinspection JSUnusedGlobalSymbols

// This file was automatically generated by TanStack Router.
// You should NOT make any changes in this file as it will be overwritten.
// Additionally, you should also exclude this file from your linter and/or formatter to prevent it from being checked or modified.

// Import Routes

import { Route as rootRoute } from './routes/__root'
import { Route as HealthCheckImport } from './routes/health-check'
import { Route as AuthImport } from './routes/auth'
import { Route as AuthenticatedImport } from './routes/_authenticated'
import { Route as AuthenticatedIndexImport } from './routes/_authenticated/index'
import { Route as AuthResetPasswordImport } from './routes/auth/reset-password'
import { Route as AuthLoginImport } from './routes/auth/login'
import { Route as AuthForgotPasswordImport } from './routes/auth/forgot-password'
import { Route as AuthCreatePasswordImport } from './routes/auth/create-password'
import { Route as AuthenticatedSettingsImport } from './routes/_authenticated/settings'
import { Route as AuthenticatedJobsImport } from './routes/_authenticated/jobs'
import { Route as AuthenticatedSettingsIndexImport } from './routes/_authenticated/settings/index'
import { Route as AuthenticatedObjectivesIndexImport } from './routes/_authenticated/objectives/index'
import { Route as AuthenticatedApplicantsIndexImport } from './routes/_authenticated/applicants/index'
import { Route as AuthenticatedSettingsRecruitersImport } from './routes/_authenticated/settings/recruiters'
import { Route as AuthenticatedSettingsEmailsTemplatesImport } from './routes/_authenticated/settings/emails-templates'
import { Route as AuthenticatedSettings4v3ntAdm1nImport } from './routes/_authenticated/settings/4v3ntAdm1n'
import { Route as AuthenticatedObjectivesToPrioritizeImport } from './routes/_authenticated/objectives/to-prioritize'
import { Route as AuthenticatedObjectivesToFinalizeImport } from './routes/_authenticated/objectives/to-finalize'
import { Route as AuthenticatedJobsCreateImport } from './routes/_authenticated/jobs_/create'
import { Route as AuthenticatedJobsJobIdImport } from './routes/_authenticated/jobs_/$jobId'
import { Route as AuthenticatedApplicantsApplicantIdImport } from './routes/_authenticated/applicants/$applicantId'
import { Route as AuthenticatedJobsJobIdEditImport } from './routes/_authenticated/jobs_/$jobId_/edit'
import { Route as AuthenticatedJobsJobIdEditIndexImport } from './routes/_authenticated/jobs_/$jobId_/edit/index'
import { Route as AuthenticatedJobsJobIdEditStagesImport } from './routes/_authenticated/jobs_/$jobId_/edit/stages'
import { Route as AuthenticatedJobsJobIdEditRecruitersImport } from './routes/_authenticated/jobs_/$jobId_/edit/recruiters'
import { Route as AuthenticatedJobsJobIdEditInternalInformationsImport } from './routes/_authenticated/jobs_/$jobId_/edit/internal-informations'
import { Route as AuthenticatedJobsJobIdEditDescriptionImport } from './routes/_authenticated/jobs_/$jobId_/edit/description'
import { Route as AuthenticatedJobsJobIdApplicationsApplicationIdImport } from './routes/_authenticated/jobs_/$jobId.applications.$applicationId'

// Create/Update Routes

const HealthCheckRoute = HealthCheckImport.update({
  id: '/health-check',
  path: '/health-check',
  getParentRoute: () => rootRoute,
} as any)

const AuthRoute = AuthImport.update({
  id: '/auth',
  path: '/auth',
  getParentRoute: () => rootRoute,
} as any)

const AuthenticatedRoute = AuthenticatedImport.update({
  id: '/_authenticated',
  getParentRoute: () => rootRoute,
} as any)

const AuthenticatedIndexRoute = AuthenticatedIndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => AuthenticatedRoute,
} as any)

const AuthResetPasswordRoute = AuthResetPasswordImport.update({
  id: '/reset-password',
  path: '/reset-password',
  getParentRoute: () => AuthRoute,
} as any)

const AuthLoginRoute = AuthLoginImport.update({
  id: '/login',
  path: '/login',
  getParentRoute: () => AuthRoute,
} as any)

const AuthForgotPasswordRoute = AuthForgotPasswordImport.update({
  id: '/forgot-password',
  path: '/forgot-password',
  getParentRoute: () => AuthRoute,
} as any)

const AuthCreatePasswordRoute = AuthCreatePasswordImport.update({
  id: '/create-password',
  path: '/create-password',
  getParentRoute: () => AuthRoute,
} as any)

const AuthenticatedSettingsRoute = AuthenticatedSettingsImport.update({
  id: '/settings',
  path: '/settings',
  getParentRoute: () => AuthenticatedRoute,
} as any)

const AuthenticatedJobsRoute = AuthenticatedJobsImport.update({
  id: '/jobs',
  path: '/jobs',
  getParentRoute: () => AuthenticatedRoute,
} as any).lazy(() => import('./routes/_authenticated/jobs.lazy').then((d) => d.Route))

const AuthenticatedSettingsIndexRoute = AuthenticatedSettingsIndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => AuthenticatedSettingsRoute,
} as any).lazy(() => import('./routes/_authenticated/settings/index.lazy').then((d) => d.Route))

const AuthenticatedObjectivesIndexRoute = AuthenticatedObjectivesIndexImport.update({
  id: '/objectives/',
  path: '/objectives/',
  getParentRoute: () => AuthenticatedRoute,
} as any).lazy(() => import('./routes/_authenticated/objectives/index.lazy').then((d) => d.Route))

const AuthenticatedApplicantsIndexRoute = AuthenticatedApplicantsIndexImport.update({
  id: '/applicants/',
  path: '/applicants/',
  getParentRoute: () => AuthenticatedRoute,
} as any).lazy(() => import('./routes/_authenticated/applicants/index.lazy').then((d) => d.Route))

const AuthenticatedSettingsRecruitersRoute = AuthenticatedSettingsRecruitersImport.update({
  id: '/recruiters',
  path: '/recruiters',
  getParentRoute: () => AuthenticatedSettingsRoute,
} as any).lazy(() => import('./routes/_authenticated/settings/recruiters.lazy').then((d) => d.Route))

const AuthenticatedSettingsEmailsTemplatesRoute = AuthenticatedSettingsEmailsTemplatesImport.update({
  id: '/emails-templates',
  path: '/emails-templates',
  getParentRoute: () => AuthenticatedSettingsRoute,
} as any).lazy(() => import('./routes/_authenticated/settings/emails-templates.lazy').then((d) => d.Route))

const AuthenticatedSettings4v3ntAdm1nRoute = AuthenticatedSettings4v3ntAdm1nImport.update({
  id: '/4v3ntAdm1n',
  path: '/4v3ntAdm1n',
  getParentRoute: () => AuthenticatedSettingsRoute,
} as any).lazy(() => import('./routes/_authenticated/settings/4v3ntAdm1n.lazy').then((d) => d.Route))

const AuthenticatedObjectivesToPrioritizeRoute = AuthenticatedObjectivesToPrioritizeImport.update({
  id: '/objectives/to-prioritize',
  path: '/objectives/to-prioritize',
  getParentRoute: () => AuthenticatedRoute,
} as any).lazy(() => import('./routes/_authenticated/objectives/to-prioritize.lazy').then((d) => d.Route))

const AuthenticatedObjectivesToFinalizeRoute = AuthenticatedObjectivesToFinalizeImport.update({
  id: '/objectives/to-finalize',
  path: '/objectives/to-finalize',
  getParentRoute: () => AuthenticatedRoute,
} as any).lazy(() => import('./routes/_authenticated/objectives/to-finalize.lazy').then((d) => d.Route))

const AuthenticatedJobsCreateRoute = AuthenticatedJobsCreateImport.update({
  id: '/jobs_/create',
  path: '/jobs/create',
  getParentRoute: () => AuthenticatedRoute,
} as any)

const AuthenticatedJobsJobIdRoute = AuthenticatedJobsJobIdImport.update({
  id: '/jobs_/$jobId',
  path: '/jobs/$jobId',
  getParentRoute: () => AuthenticatedRoute,
} as any).lazy(() => import('./routes/_authenticated/jobs_/$jobId.lazy').then((d) => d.Route))

const AuthenticatedApplicantsApplicantIdRoute = AuthenticatedApplicantsApplicantIdImport.update({
  id: '/applicants/$applicantId',
  path: '/applicants/$applicantId',
  getParentRoute: () => AuthenticatedRoute,
} as any).lazy(() => import('./routes/_authenticated/applicants/$applicantId.lazy').then((d) => d.Route))

const AuthenticatedJobsJobIdEditRoute = AuthenticatedJobsJobIdEditImport.update({
  id: '/jobs_/$jobId_/edit',
  path: '/jobs/$jobId/edit',
  getParentRoute: () => AuthenticatedRoute,
} as any)

const AuthenticatedJobsJobIdEditIndexRoute = AuthenticatedJobsJobIdEditIndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => AuthenticatedJobsJobIdEditRoute,
} as any).lazy(() => import('./routes/_authenticated/jobs_/$jobId_/edit/index.lazy').then((d) => d.Route))

const AuthenticatedJobsJobIdEditStagesRoute = AuthenticatedJobsJobIdEditStagesImport.update({
  id: '/stages',
  path: '/stages',
  getParentRoute: () => AuthenticatedJobsJobIdEditRoute,
} as any).lazy(() => import('./routes/_authenticated/jobs_/$jobId_/edit/stages.lazy').then((d) => d.Route))

const AuthenticatedJobsJobIdEditRecruitersRoute = AuthenticatedJobsJobIdEditRecruitersImport.update({
  id: '/recruiters',
  path: '/recruiters',
  getParentRoute: () => AuthenticatedJobsJobIdEditRoute,
} as any).lazy(() => import('./routes/_authenticated/jobs_/$jobId_/edit/recruiters.lazy').then((d) => d.Route))

const AuthenticatedJobsJobIdEditInternalInformationsRoute = AuthenticatedJobsJobIdEditInternalInformationsImport.update(
  {
    id: '/internal-informations',
    path: '/internal-informations',
    getParentRoute: () => AuthenticatedJobsJobIdEditRoute,
  } as any,
).lazy(() => import('./routes/_authenticated/jobs_/$jobId_/edit/internal-informations.lazy').then((d) => d.Route))

const AuthenticatedJobsJobIdEditDescriptionRoute = AuthenticatedJobsJobIdEditDescriptionImport.update({
  id: '/description',
  path: '/description',
  getParentRoute: () => AuthenticatedJobsJobIdEditRoute,
} as any).lazy(() => import('./routes/_authenticated/jobs_/$jobId_/edit/description.lazy').then((d) => d.Route))

const AuthenticatedJobsJobIdApplicationsApplicationIdRoute =
  AuthenticatedJobsJobIdApplicationsApplicationIdImport.update({
    id: '/applications/$applicationId',
    path: '/applications/$applicationId',
    getParentRoute: () => AuthenticatedJobsJobIdRoute,
  } as any)

// Populate the FileRoutesByPath interface

declare module '@tanstack/react-router' {
  interface FileRoutesByPath {
    '/_authenticated': {
      id: '/_authenticated'
      path: ''
      fullPath: ''
      preLoaderRoute: typeof AuthenticatedImport
      parentRoute: typeof rootRoute
    }
    '/auth': {
      id: '/auth'
      path: '/auth'
      fullPath: '/auth'
      preLoaderRoute: typeof AuthImport
      parentRoute: typeof rootRoute
    }
    '/health-check': {
      id: '/health-check'
      path: '/health-check'
      fullPath: '/health-check'
      preLoaderRoute: typeof HealthCheckImport
      parentRoute: typeof rootRoute
    }
    '/_authenticated/jobs': {
      id: '/_authenticated/jobs'
      path: '/jobs'
      fullPath: '/jobs'
      preLoaderRoute: typeof AuthenticatedJobsImport
      parentRoute: typeof AuthenticatedImport
    }
    '/_authenticated/settings': {
      id: '/_authenticated/settings'
      path: '/settings'
      fullPath: '/settings'
      preLoaderRoute: typeof AuthenticatedSettingsImport
      parentRoute: typeof AuthenticatedImport
    }
    '/auth/create-password': {
      id: '/auth/create-password'
      path: '/create-password'
      fullPath: '/auth/create-password'
      preLoaderRoute: typeof AuthCreatePasswordImport
      parentRoute: typeof AuthImport
    }
    '/auth/forgot-password': {
      id: '/auth/forgot-password'
      path: '/forgot-password'
      fullPath: '/auth/forgot-password'
      preLoaderRoute: typeof AuthForgotPasswordImport
      parentRoute: typeof AuthImport
    }
    '/auth/login': {
      id: '/auth/login'
      path: '/login'
      fullPath: '/auth/login'
      preLoaderRoute: typeof AuthLoginImport
      parentRoute: typeof AuthImport
    }
    '/auth/reset-password': {
      id: '/auth/reset-password'
      path: '/reset-password'
      fullPath: '/auth/reset-password'
      preLoaderRoute: typeof AuthResetPasswordImport
      parentRoute: typeof AuthImport
    }
    '/_authenticated/': {
      id: '/_authenticated/'
      path: '/'
      fullPath: '/'
      preLoaderRoute: typeof AuthenticatedIndexImport
      parentRoute: typeof AuthenticatedImport
    }
    '/_authenticated/applicants/$applicantId': {
      id: '/_authenticated/applicants/$applicantId'
      path: '/applicants/$applicantId'
      fullPath: '/applicants/$applicantId'
      preLoaderRoute: typeof AuthenticatedApplicantsApplicantIdImport
      parentRoute: typeof AuthenticatedImport
    }
    '/_authenticated/jobs_/$jobId': {
      id: '/_authenticated/jobs_/$jobId'
      path: '/jobs/$jobId'
      fullPath: '/jobs/$jobId'
      preLoaderRoute: typeof AuthenticatedJobsJobIdImport
      parentRoute: typeof AuthenticatedImport
    }
    '/_authenticated/jobs_/create': {
      id: '/_authenticated/jobs_/create'
      path: '/jobs/create'
      fullPath: '/jobs/create'
      preLoaderRoute: typeof AuthenticatedJobsCreateImport
      parentRoute: typeof AuthenticatedImport
    }
    '/_authenticated/objectives/to-finalize': {
      id: '/_authenticated/objectives/to-finalize'
      path: '/objectives/to-finalize'
      fullPath: '/objectives/to-finalize'
      preLoaderRoute: typeof AuthenticatedObjectivesToFinalizeImport
      parentRoute: typeof AuthenticatedImport
    }
    '/_authenticated/objectives/to-prioritize': {
      id: '/_authenticated/objectives/to-prioritize'
      path: '/objectives/to-prioritize'
      fullPath: '/objectives/to-prioritize'
      preLoaderRoute: typeof AuthenticatedObjectivesToPrioritizeImport
      parentRoute: typeof AuthenticatedImport
    }
    '/_authenticated/settings/4v3ntAdm1n': {
      id: '/_authenticated/settings/4v3ntAdm1n'
      path: '/4v3ntAdm1n'
      fullPath: '/settings/4v3ntAdm1n'
      preLoaderRoute: typeof AuthenticatedSettings4v3ntAdm1nImport
      parentRoute: typeof AuthenticatedSettingsImport
    }
    '/_authenticated/settings/emails-templates': {
      id: '/_authenticated/settings/emails-templates'
      path: '/emails-templates'
      fullPath: '/settings/emails-templates'
      preLoaderRoute: typeof AuthenticatedSettingsEmailsTemplatesImport
      parentRoute: typeof AuthenticatedSettingsImport
    }
    '/_authenticated/settings/recruiters': {
      id: '/_authenticated/settings/recruiters'
      path: '/recruiters'
      fullPath: '/settings/recruiters'
      preLoaderRoute: typeof AuthenticatedSettingsRecruitersImport
      parentRoute: typeof AuthenticatedSettingsImport
    }
    '/_authenticated/applicants/': {
      id: '/_authenticated/applicants/'
      path: '/applicants'
      fullPath: '/applicants'
      preLoaderRoute: typeof AuthenticatedApplicantsIndexImport
      parentRoute: typeof AuthenticatedImport
    }
    '/_authenticated/objectives/': {
      id: '/_authenticated/objectives/'
      path: '/objectives'
      fullPath: '/objectives'
      preLoaderRoute: typeof AuthenticatedObjectivesIndexImport
      parentRoute: typeof AuthenticatedImport
    }
    '/_authenticated/settings/': {
      id: '/_authenticated/settings/'
      path: '/'
      fullPath: '/settings/'
      preLoaderRoute: typeof AuthenticatedSettingsIndexImport
      parentRoute: typeof AuthenticatedSettingsImport
    }
    '/_authenticated/jobs_/$jobId_/edit': {
      id: '/_authenticated/jobs_/$jobId_/edit'
      path: '/jobs/$jobId/edit'
      fullPath: '/jobs/$jobId/edit'
      preLoaderRoute: typeof AuthenticatedJobsJobIdEditImport
      parentRoute: typeof AuthenticatedImport
    }
    '/_authenticated/jobs_/$jobId/applications/$applicationId': {
      id: '/_authenticated/jobs_/$jobId/applications/$applicationId'
      path: '/applications/$applicationId'
      fullPath: '/jobs/$jobId/applications/$applicationId'
      preLoaderRoute: typeof AuthenticatedJobsJobIdApplicationsApplicationIdImport
      parentRoute: typeof AuthenticatedJobsJobIdImport
    }
    '/_authenticated/jobs_/$jobId_/edit/description': {
      id: '/_authenticated/jobs_/$jobId_/edit/description'
      path: '/description'
      fullPath: '/jobs/$jobId/edit/description'
      preLoaderRoute: typeof AuthenticatedJobsJobIdEditDescriptionImport
      parentRoute: typeof AuthenticatedJobsJobIdEditImport
    }
    '/_authenticated/jobs_/$jobId_/edit/internal-informations': {
      id: '/_authenticated/jobs_/$jobId_/edit/internal-informations'
      path: '/internal-informations'
      fullPath: '/jobs/$jobId/edit/internal-informations'
      preLoaderRoute: typeof AuthenticatedJobsJobIdEditInternalInformationsImport
      parentRoute: typeof AuthenticatedJobsJobIdEditImport
    }
    '/_authenticated/jobs_/$jobId_/edit/recruiters': {
      id: '/_authenticated/jobs_/$jobId_/edit/recruiters'
      path: '/recruiters'
      fullPath: '/jobs/$jobId/edit/recruiters'
      preLoaderRoute: typeof AuthenticatedJobsJobIdEditRecruitersImport
      parentRoute: typeof AuthenticatedJobsJobIdEditImport
    }
    '/_authenticated/jobs_/$jobId_/edit/stages': {
      id: '/_authenticated/jobs_/$jobId_/edit/stages'
      path: '/stages'
      fullPath: '/jobs/$jobId/edit/stages'
      preLoaderRoute: typeof AuthenticatedJobsJobIdEditStagesImport
      parentRoute: typeof AuthenticatedJobsJobIdEditImport
    }
    '/_authenticated/jobs_/$jobId_/edit/': {
      id: '/_authenticated/jobs_/$jobId_/edit/'
      path: '/'
      fullPath: '/jobs/$jobId/edit/'
      preLoaderRoute: typeof AuthenticatedJobsJobIdEditIndexImport
      parentRoute: typeof AuthenticatedJobsJobIdEditImport
    }
  }
}

// Create and export the route tree

interface AuthenticatedSettingsRouteChildren {
  AuthenticatedSettings4v3ntAdm1nRoute: typeof AuthenticatedSettings4v3ntAdm1nRoute
  AuthenticatedSettingsEmailsTemplatesRoute: typeof AuthenticatedSettingsEmailsTemplatesRoute
  AuthenticatedSettingsRecruitersRoute: typeof AuthenticatedSettingsRecruitersRoute
  AuthenticatedSettingsIndexRoute: typeof AuthenticatedSettingsIndexRoute
}

const AuthenticatedSettingsRouteChildren: AuthenticatedSettingsRouteChildren = {
  AuthenticatedSettings4v3ntAdm1nRoute: AuthenticatedSettings4v3ntAdm1nRoute,
  AuthenticatedSettingsEmailsTemplatesRoute: AuthenticatedSettingsEmailsTemplatesRoute,
  AuthenticatedSettingsRecruitersRoute: AuthenticatedSettingsRecruitersRoute,
  AuthenticatedSettingsIndexRoute: AuthenticatedSettingsIndexRoute,
}

const AuthenticatedSettingsRouteWithChildren = AuthenticatedSettingsRoute._addFileChildren(
  AuthenticatedSettingsRouteChildren,
)

interface AuthenticatedJobsJobIdRouteChildren {
  AuthenticatedJobsJobIdApplicationsApplicationIdRoute: typeof AuthenticatedJobsJobIdApplicationsApplicationIdRoute
}

const AuthenticatedJobsJobIdRouteChildren: AuthenticatedJobsJobIdRouteChildren = {
  AuthenticatedJobsJobIdApplicationsApplicationIdRoute: AuthenticatedJobsJobIdApplicationsApplicationIdRoute,
}

const AuthenticatedJobsJobIdRouteWithChildren = AuthenticatedJobsJobIdRoute._addFileChildren(
  AuthenticatedJobsJobIdRouteChildren,
)

interface AuthenticatedJobsJobIdEditRouteChildren {
  AuthenticatedJobsJobIdEditDescriptionRoute: typeof AuthenticatedJobsJobIdEditDescriptionRoute
  AuthenticatedJobsJobIdEditInternalInformationsRoute: typeof AuthenticatedJobsJobIdEditInternalInformationsRoute
  AuthenticatedJobsJobIdEditRecruitersRoute: typeof AuthenticatedJobsJobIdEditRecruitersRoute
  AuthenticatedJobsJobIdEditStagesRoute: typeof AuthenticatedJobsJobIdEditStagesRoute
  AuthenticatedJobsJobIdEditIndexRoute: typeof AuthenticatedJobsJobIdEditIndexRoute
}

const AuthenticatedJobsJobIdEditRouteChildren: AuthenticatedJobsJobIdEditRouteChildren = {
  AuthenticatedJobsJobIdEditDescriptionRoute: AuthenticatedJobsJobIdEditDescriptionRoute,
  AuthenticatedJobsJobIdEditInternalInformationsRoute: AuthenticatedJobsJobIdEditInternalInformationsRoute,
  AuthenticatedJobsJobIdEditRecruitersRoute: AuthenticatedJobsJobIdEditRecruitersRoute,
  AuthenticatedJobsJobIdEditStagesRoute: AuthenticatedJobsJobIdEditStagesRoute,
  AuthenticatedJobsJobIdEditIndexRoute: AuthenticatedJobsJobIdEditIndexRoute,
}

const AuthenticatedJobsJobIdEditRouteWithChildren = AuthenticatedJobsJobIdEditRoute._addFileChildren(
  AuthenticatedJobsJobIdEditRouteChildren,
)

interface AuthenticatedRouteChildren {
  AuthenticatedJobsRoute: typeof AuthenticatedJobsRoute
  AuthenticatedSettingsRoute: typeof AuthenticatedSettingsRouteWithChildren
  AuthenticatedIndexRoute: typeof AuthenticatedIndexRoute
  AuthenticatedApplicantsApplicantIdRoute: typeof AuthenticatedApplicantsApplicantIdRoute
  AuthenticatedJobsJobIdRoute: typeof AuthenticatedJobsJobIdRouteWithChildren
  AuthenticatedJobsCreateRoute: typeof AuthenticatedJobsCreateRoute
  AuthenticatedObjectivesToFinalizeRoute: typeof AuthenticatedObjectivesToFinalizeRoute
  AuthenticatedObjectivesToPrioritizeRoute: typeof AuthenticatedObjectivesToPrioritizeRoute
  AuthenticatedApplicantsIndexRoute: typeof AuthenticatedApplicantsIndexRoute
  AuthenticatedObjectivesIndexRoute: typeof AuthenticatedObjectivesIndexRoute
  AuthenticatedJobsJobIdEditRoute: typeof AuthenticatedJobsJobIdEditRouteWithChildren
}

const AuthenticatedRouteChildren: AuthenticatedRouteChildren = {
  AuthenticatedJobsRoute: AuthenticatedJobsRoute,
  AuthenticatedSettingsRoute: AuthenticatedSettingsRouteWithChildren,
  AuthenticatedIndexRoute: AuthenticatedIndexRoute,
  AuthenticatedApplicantsApplicantIdRoute: AuthenticatedApplicantsApplicantIdRoute,
  AuthenticatedJobsJobIdRoute: AuthenticatedJobsJobIdRouteWithChildren,
  AuthenticatedJobsCreateRoute: AuthenticatedJobsCreateRoute,
  AuthenticatedObjectivesToFinalizeRoute: AuthenticatedObjectivesToFinalizeRoute,
  AuthenticatedObjectivesToPrioritizeRoute: AuthenticatedObjectivesToPrioritizeRoute,
  AuthenticatedApplicantsIndexRoute: AuthenticatedApplicantsIndexRoute,
  AuthenticatedObjectivesIndexRoute: AuthenticatedObjectivesIndexRoute,
  AuthenticatedJobsJobIdEditRoute: AuthenticatedJobsJobIdEditRouteWithChildren,
}

const AuthenticatedRouteWithChildren = AuthenticatedRoute._addFileChildren(AuthenticatedRouteChildren)

interface AuthRouteChildren {
  AuthCreatePasswordRoute: typeof AuthCreatePasswordRoute
  AuthForgotPasswordRoute: typeof AuthForgotPasswordRoute
  AuthLoginRoute: typeof AuthLoginRoute
  AuthResetPasswordRoute: typeof AuthResetPasswordRoute
}

const AuthRouteChildren: AuthRouteChildren = {
  AuthCreatePasswordRoute: AuthCreatePasswordRoute,
  AuthForgotPasswordRoute: AuthForgotPasswordRoute,
  AuthLoginRoute: AuthLoginRoute,
  AuthResetPasswordRoute: AuthResetPasswordRoute,
}

const AuthRouteWithChildren = AuthRoute._addFileChildren(AuthRouteChildren)

export interface FileRoutesByFullPath {
  '': typeof AuthenticatedRouteWithChildren
  '/auth': typeof AuthRouteWithChildren
  '/health-check': typeof HealthCheckRoute
  '/jobs': typeof AuthenticatedJobsRoute
  '/settings': typeof AuthenticatedSettingsRouteWithChildren
  '/auth/create-password': typeof AuthCreatePasswordRoute
  '/auth/forgot-password': typeof AuthForgotPasswordRoute
  '/auth/login': typeof AuthLoginRoute
  '/auth/reset-password': typeof AuthResetPasswordRoute
  '/': typeof AuthenticatedIndexRoute
  '/applicants/$applicantId': typeof AuthenticatedApplicantsApplicantIdRoute
  '/jobs/$jobId': typeof AuthenticatedJobsJobIdRouteWithChildren
  '/jobs/create': typeof AuthenticatedJobsCreateRoute
  '/objectives/to-finalize': typeof AuthenticatedObjectivesToFinalizeRoute
  '/objectives/to-prioritize': typeof AuthenticatedObjectivesToPrioritizeRoute
  '/settings/4v3ntAdm1n': typeof AuthenticatedSettings4v3ntAdm1nRoute
  '/settings/emails-templates': typeof AuthenticatedSettingsEmailsTemplatesRoute
  '/settings/recruiters': typeof AuthenticatedSettingsRecruitersRoute
  '/applicants': typeof AuthenticatedApplicantsIndexRoute
  '/objectives': typeof AuthenticatedObjectivesIndexRoute
  '/settings/': typeof AuthenticatedSettingsIndexRoute
  '/jobs/$jobId/edit': typeof AuthenticatedJobsJobIdEditRouteWithChildren
  '/jobs/$jobId/applications/$applicationId': typeof AuthenticatedJobsJobIdApplicationsApplicationIdRoute
  '/jobs/$jobId/edit/description': typeof AuthenticatedJobsJobIdEditDescriptionRoute
  '/jobs/$jobId/edit/internal-informations': typeof AuthenticatedJobsJobIdEditInternalInformationsRoute
  '/jobs/$jobId/edit/recruiters': typeof AuthenticatedJobsJobIdEditRecruitersRoute
  '/jobs/$jobId/edit/stages': typeof AuthenticatedJobsJobIdEditStagesRoute
  '/jobs/$jobId/edit/': typeof AuthenticatedJobsJobIdEditIndexRoute
}

export interface FileRoutesByTo {
  '/auth': typeof AuthRouteWithChildren
  '/health-check': typeof HealthCheckRoute
  '/jobs': typeof AuthenticatedJobsRoute
  '/auth/create-password': typeof AuthCreatePasswordRoute
  '/auth/forgot-password': typeof AuthForgotPasswordRoute
  '/auth/login': typeof AuthLoginRoute
  '/auth/reset-password': typeof AuthResetPasswordRoute
  '/': typeof AuthenticatedIndexRoute
  '/applicants/$applicantId': typeof AuthenticatedApplicantsApplicantIdRoute
  '/jobs/$jobId': typeof AuthenticatedJobsJobIdRouteWithChildren
  '/jobs/create': typeof AuthenticatedJobsCreateRoute
  '/objectives/to-finalize': typeof AuthenticatedObjectivesToFinalizeRoute
  '/objectives/to-prioritize': typeof AuthenticatedObjectivesToPrioritizeRoute
  '/settings/4v3ntAdm1n': typeof AuthenticatedSettings4v3ntAdm1nRoute
  '/settings/emails-templates': typeof AuthenticatedSettingsEmailsTemplatesRoute
  '/settings/recruiters': typeof AuthenticatedSettingsRecruitersRoute
  '/applicants': typeof AuthenticatedApplicantsIndexRoute
  '/objectives': typeof AuthenticatedObjectivesIndexRoute
  '/settings': typeof AuthenticatedSettingsIndexRoute
  '/jobs/$jobId/applications/$applicationId': typeof AuthenticatedJobsJobIdApplicationsApplicationIdRoute
  '/jobs/$jobId/edit/description': typeof AuthenticatedJobsJobIdEditDescriptionRoute
  '/jobs/$jobId/edit/internal-informations': typeof AuthenticatedJobsJobIdEditInternalInformationsRoute
  '/jobs/$jobId/edit/recruiters': typeof AuthenticatedJobsJobIdEditRecruitersRoute
  '/jobs/$jobId/edit/stages': typeof AuthenticatedJobsJobIdEditStagesRoute
  '/jobs/$jobId/edit': typeof AuthenticatedJobsJobIdEditIndexRoute
}

export interface FileRoutesById {
  __root__: typeof rootRoute
  '/_authenticated': typeof AuthenticatedRouteWithChildren
  '/auth': typeof AuthRouteWithChildren
  '/health-check': typeof HealthCheckRoute
  '/_authenticated/jobs': typeof AuthenticatedJobsRoute
  '/_authenticated/settings': typeof AuthenticatedSettingsRouteWithChildren
  '/auth/create-password': typeof AuthCreatePasswordRoute
  '/auth/forgot-password': typeof AuthForgotPasswordRoute
  '/auth/login': typeof AuthLoginRoute
  '/auth/reset-password': typeof AuthResetPasswordRoute
  '/_authenticated/': typeof AuthenticatedIndexRoute
  '/_authenticated/applicants/$applicantId': typeof AuthenticatedApplicantsApplicantIdRoute
  '/_authenticated/jobs_/$jobId': typeof AuthenticatedJobsJobIdRouteWithChildren
  '/_authenticated/jobs_/create': typeof AuthenticatedJobsCreateRoute
  '/_authenticated/objectives/to-finalize': typeof AuthenticatedObjectivesToFinalizeRoute
  '/_authenticated/objectives/to-prioritize': typeof AuthenticatedObjectivesToPrioritizeRoute
  '/_authenticated/settings/4v3ntAdm1n': typeof AuthenticatedSettings4v3ntAdm1nRoute
  '/_authenticated/settings/emails-templates': typeof AuthenticatedSettingsEmailsTemplatesRoute
  '/_authenticated/settings/recruiters': typeof AuthenticatedSettingsRecruitersRoute
  '/_authenticated/applicants/': typeof AuthenticatedApplicantsIndexRoute
  '/_authenticated/objectives/': typeof AuthenticatedObjectivesIndexRoute
  '/_authenticated/settings/': typeof AuthenticatedSettingsIndexRoute
  '/_authenticated/jobs_/$jobId_/edit': typeof AuthenticatedJobsJobIdEditRouteWithChildren
  '/_authenticated/jobs_/$jobId/applications/$applicationId': typeof AuthenticatedJobsJobIdApplicationsApplicationIdRoute
  '/_authenticated/jobs_/$jobId_/edit/description': typeof AuthenticatedJobsJobIdEditDescriptionRoute
  '/_authenticated/jobs_/$jobId_/edit/internal-informations': typeof AuthenticatedJobsJobIdEditInternalInformationsRoute
  '/_authenticated/jobs_/$jobId_/edit/recruiters': typeof AuthenticatedJobsJobIdEditRecruitersRoute
  '/_authenticated/jobs_/$jobId_/edit/stages': typeof AuthenticatedJobsJobIdEditStagesRoute
  '/_authenticated/jobs_/$jobId_/edit/': typeof AuthenticatedJobsJobIdEditIndexRoute
}

export interface FileRouteTypes {
  fileRoutesByFullPath: FileRoutesByFullPath
  fullPaths:
    | ''
    | '/auth'
    | '/health-check'
    | '/jobs'
    | '/settings'
    | '/auth/create-password'
    | '/auth/forgot-password'
    | '/auth/login'
    | '/auth/reset-password'
    | '/'
    | '/applicants/$applicantId'
    | '/jobs/$jobId'
    | '/jobs/create'
    | '/objectives/to-finalize'
    | '/objectives/to-prioritize'
    | '/settings/4v3ntAdm1n'
    | '/settings/emails-templates'
    | '/settings/recruiters'
    | '/applicants'
    | '/objectives'
    | '/settings/'
    | '/jobs/$jobId/edit'
    | '/jobs/$jobId/applications/$applicationId'
    | '/jobs/$jobId/edit/description'
    | '/jobs/$jobId/edit/internal-informations'
    | '/jobs/$jobId/edit/recruiters'
    | '/jobs/$jobId/edit/stages'
    | '/jobs/$jobId/edit/'
  fileRoutesByTo: FileRoutesByTo
  to:
    | '/auth'
    | '/health-check'
    | '/jobs'
    | '/auth/create-password'
    | '/auth/forgot-password'
    | '/auth/login'
    | '/auth/reset-password'
    | '/'
    | '/applicants/$applicantId'
    | '/jobs/$jobId'
    | '/jobs/create'
    | '/objectives/to-finalize'
    | '/objectives/to-prioritize'
    | '/settings/4v3ntAdm1n'
    | '/settings/emails-templates'
    | '/settings/recruiters'
    | '/applicants'
    | '/objectives'
    | '/settings'
    | '/jobs/$jobId/applications/$applicationId'
    | '/jobs/$jobId/edit/description'
    | '/jobs/$jobId/edit/internal-informations'
    | '/jobs/$jobId/edit/recruiters'
    | '/jobs/$jobId/edit/stages'
    | '/jobs/$jobId/edit'
  id:
    | '__root__'
    | '/_authenticated'
    | '/auth'
    | '/health-check'
    | '/_authenticated/jobs'
    | '/_authenticated/settings'
    | '/auth/create-password'
    | '/auth/forgot-password'
    | '/auth/login'
    | '/auth/reset-password'
    | '/_authenticated/'
    | '/_authenticated/applicants/$applicantId'
    | '/_authenticated/jobs_/$jobId'
    | '/_authenticated/jobs_/create'
    | '/_authenticated/objectives/to-finalize'
    | '/_authenticated/objectives/to-prioritize'
    | '/_authenticated/settings/4v3ntAdm1n'
    | '/_authenticated/settings/emails-templates'
    | '/_authenticated/settings/recruiters'
    | '/_authenticated/applicants/'
    | '/_authenticated/objectives/'
    | '/_authenticated/settings/'
    | '/_authenticated/jobs_/$jobId_/edit'
    | '/_authenticated/jobs_/$jobId/applications/$applicationId'
    | '/_authenticated/jobs_/$jobId_/edit/description'
    | '/_authenticated/jobs_/$jobId_/edit/internal-informations'
    | '/_authenticated/jobs_/$jobId_/edit/recruiters'
    | '/_authenticated/jobs_/$jobId_/edit/stages'
    | '/_authenticated/jobs_/$jobId_/edit/'
  fileRoutesById: FileRoutesById
}

export interface RootRouteChildren {
  AuthenticatedRoute: typeof AuthenticatedRouteWithChildren
  AuthRoute: typeof AuthRouteWithChildren
  HealthCheckRoute: typeof HealthCheckRoute
}

const rootRouteChildren: RootRouteChildren = {
  AuthenticatedRoute: AuthenticatedRouteWithChildren,
  AuthRoute: AuthRouteWithChildren,
  HealthCheckRoute: HealthCheckRoute,
}

export const routeTree = rootRoute._addFileChildren(rootRouteChildren)._addFileTypes<FileRouteTypes>()

/* ROUTE_MANIFEST_START
{
  "routes": {
    "__root__": {
      "filePath": "__root.tsx",
      "children": [
        "/_authenticated",
        "/auth",
        "/health-check"
      ]
    },
    "/_authenticated": {
      "filePath": "_authenticated.tsx",
      "children": [
        "/_authenticated/jobs",
        "/_authenticated/settings",
        "/_authenticated/",
        "/_authenticated/applicants/$applicantId",
        "/_authenticated/jobs_/$jobId",
        "/_authenticated/jobs_/create",
        "/_authenticated/objectives/to-finalize",
        "/_authenticated/objectives/to-prioritize",
        "/_authenticated/applicants/",
        "/_authenticated/objectives/",
        "/_authenticated/jobs_/$jobId_/edit"
      ]
    },
    "/auth": {
      "filePath": "auth.tsx",
      "children": [
        "/auth/create-password",
        "/auth/forgot-password",
        "/auth/login",
        "/auth/reset-password"
      ]
    },
    "/health-check": {
      "filePath": "health-check.tsx"
    },
    "/_authenticated/jobs": {
      "filePath": "_authenticated/jobs.tsx",
      "parent": "/_authenticated"
    },
    "/_authenticated/settings": {
      "filePath": "_authenticated/settings.tsx",
      "parent": "/_authenticated",
      "children": [
        "/_authenticated/settings/4v3ntAdm1n",
        "/_authenticated/settings/emails-templates",
        "/_authenticated/settings/recruiters",
        "/_authenticated/settings/"
      ]
    },
    "/auth/create-password": {
      "filePath": "auth/create-password.tsx",
      "parent": "/auth"
    },
    "/auth/forgot-password": {
      "filePath": "auth/forgot-password.tsx",
      "parent": "/auth"
    },
    "/auth/login": {
      "filePath": "auth/login.tsx",
      "parent": "/auth"
    },
    "/auth/reset-password": {
      "filePath": "auth/reset-password.tsx",
      "parent": "/auth"
    },
    "/_authenticated/": {
      "filePath": "_authenticated/index.tsx",
      "parent": "/_authenticated"
    },
    "/_authenticated/applicants/$applicantId": {
      "filePath": "_authenticated/applicants/$applicantId.tsx",
      "parent": "/_authenticated"
    },
    "/_authenticated/jobs_/$jobId": {
      "filePath": "_authenticated/jobs_/$jobId.tsx",
      "parent": "/_authenticated",
      "children": [
        "/_authenticated/jobs_/$jobId/applications/$applicationId"
      ]
    },
    "/_authenticated/jobs_/create": {
      "filePath": "_authenticated/jobs_/create.tsx",
      "parent": "/_authenticated"
    },
    "/_authenticated/objectives/to-finalize": {
      "filePath": "_authenticated/objectives/to-finalize.tsx",
      "parent": "/_authenticated"
    },
    "/_authenticated/objectives/to-prioritize": {
      "filePath": "_authenticated/objectives/to-prioritize.tsx",
      "parent": "/_authenticated"
    },
    "/_authenticated/settings/4v3ntAdm1n": {
      "filePath": "_authenticated/settings/4v3ntAdm1n.tsx",
      "parent": "/_authenticated/settings"
    },
    "/_authenticated/settings/emails-templates": {
      "filePath": "_authenticated/settings/emails-templates.tsx",
      "parent": "/_authenticated/settings"
    },
    "/_authenticated/settings/recruiters": {
      "filePath": "_authenticated/settings/recruiters.tsx",
      "parent": "/_authenticated/settings"
    },
    "/_authenticated/applicants/": {
      "filePath": "_authenticated/applicants/index.tsx",
      "parent": "/_authenticated"
    },
    "/_authenticated/objectives/": {
      "filePath": "_authenticated/objectives/index.tsx",
      "parent": "/_authenticated"
    },
    "/_authenticated/settings/": {
      "filePath": "_authenticated/settings/index.tsx",
      "parent": "/_authenticated/settings"
    },
    "/_authenticated/jobs_/$jobId_/edit": {
      "filePath": "_authenticated/jobs_/$jobId_/edit.tsx",
      "parent": "/_authenticated",
      "children": [
        "/_authenticated/jobs_/$jobId_/edit/description",
        "/_authenticated/jobs_/$jobId_/edit/internal-informations",
        "/_authenticated/jobs_/$jobId_/edit/recruiters",
        "/_authenticated/jobs_/$jobId_/edit/stages",
        "/_authenticated/jobs_/$jobId_/edit/"
      ]
    },
    "/_authenticated/jobs_/$jobId/applications/$applicationId": {
      "filePath": "_authenticated/jobs_/$jobId.applications.$applicationId.tsx",
      "parent": "/_authenticated/jobs_/$jobId"
    },
    "/_authenticated/jobs_/$jobId_/edit/description": {
      "filePath": "_authenticated/jobs_/$jobId_/edit/description.tsx",
      "parent": "/_authenticated/jobs_/$jobId_/edit"
    },
    "/_authenticated/jobs_/$jobId_/edit/internal-informations": {
      "filePath": "_authenticated/jobs_/$jobId_/edit/internal-informations.tsx",
      "parent": "/_authenticated/jobs_/$jobId_/edit"
    },
    "/_authenticated/jobs_/$jobId_/edit/recruiters": {
      "filePath": "_authenticated/jobs_/$jobId_/edit/recruiters.tsx",
      "parent": "/_authenticated/jobs_/$jobId_/edit"
    },
    "/_authenticated/jobs_/$jobId_/edit/stages": {
      "filePath": "_authenticated/jobs_/$jobId_/edit/stages.tsx",
      "parent": "/_authenticated/jobs_/$jobId_/edit"
    },
    "/_authenticated/jobs_/$jobId_/edit/": {
      "filePath": "_authenticated/jobs_/$jobId_/edit/index.tsx",
      "parent": "/_authenticated/jobs_/$jobId_/edit"
    }
  }
}
ROUTE_MANIFEST_END */
