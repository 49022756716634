import { Country } from '@/types/country/country'
import { JobLocation } from '@/types/job'

interface FullNameProps {
  firstname?: string | null
  lastname?: string | null
}

export const getFullName = <T extends FullNameProps>({ firstname, lastname }: T): string => {
  return `${firstname ?? ''} ${lastname ?? ''}`
}

export const getFullNameInitial = <T extends FullNameProps>({ firstname, lastname }: T): string => {
  const firstnameInitials = firstname
    ? firstname
        .split(' ')
        .map((f) => f[0])
        .join('')
    : ''

  const lastnameInitials = lastname
    ? lastname
        .split(' ')
        .map((l) => l[0])
        .join('')
    : ''

  return `${firstnameInitials}${lastnameInitials}`
}

interface JobNameProps {
  currentCompany?: string
  currentJob?: string
}

export const getJobName = <T extends JobNameProps>({ currentCompany, currentJob }: T): string => {
  if (!currentJob && !currentCompany) return '-'
  return `${currentJob || '-'} ${currentCompany ? `@ ${currentCompany}` : ''}`
}

interface ApplicantLocationProps {
  city?: string
  country: Country
}

export function formatApplicantLocation<T extends ApplicantLocationProps>({ city, country }: T): string {
  if (!city && !country.name) return '-'
  return `${city || ''}${city && country.name ? ', ' : ''}${country.name || ''}`
}

export function getUniqueLocations(jobLocations: JobLocation[]): string[] {
  const locations = jobLocations.map((location) => location.location.name)
  return [...new Set(locations)]
}

export function formatJobLocations(jobLocations: JobLocation[]): string {
  return getUniqueLocations(jobLocations).join(', ')
}

export function replaceCurlyBracesWithAtSymbol(text: string) {
  return text.replace(/{(.*?)}/g, (_match, variable) => `@${variable}`)
}

export function replaceAtSymbolWithCurlyBraces(text: string) {
  return text.replace(/(?<!\w)@(\w+)/g, (_match, variable) => `{${variable}}`)
}

export function convertVariablesToMentionHtml(text: string) {
  return text.replace(
    /({(\w+)})(?![^<>]*>[^<]*<\/a>)/g,
    '<span data-type="mention" data-label="$2" data-id="$2"></span>',
  )
}

export function convertMentionHtmlToVariables(text?: string) {
  if (!text) return ''
  return text.replace(/<span[^>]*data-id="([^"]+)"[^>]*>[^<]*<\/span>/g, '{$1}')
}

export function removeHTMLTags(text: string) {
  return text.replace(/<[^>]*>/g, '')
}

export function extractUniqueMentionIds(html: string): string[] {
  const parser = new DOMParser()
  const doc = parser.parseFromString(html, 'text/html')
  const mentions = doc.querySelectorAll('span[data-id]')
  return [
    ...new Set(
      Array.from(mentions)
        .map((span) => span.getAttribute('data-id'))
        .filter(Boolean),
    ),
  ] as string[]
}

export function capitalizeFirstLetter(text: string): string {
  return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase()
}
