import { createFileRoute } from '@tanstack/react-router'

import { PageContent } from '@/components/page-content'
import { PageHeaderSkeleton } from '@/components/skeleton/page-header-skeleton'
import { TableSkeleton } from '@/components/ui/table'
import { recruitersQuery } from '@/queries/use-recruiters-queries'

export const Route = createFileRoute('/_authenticated/settings/recruiters')({
  beforeLoad: () => {
    return {
      meta: {
        title: 'Settings - Recruiters',
      },
    }
  },
  loader: ({ context: { queryClient } }) => queryClient.ensureQueryData(recruitersQuery),
  pendingComponent: () => (
    <>
      <PageHeaderSkeleton cta title="Recruiters" />
      <PageContent>
        <TableSkeleton />
      </PageContent>
    </>
  ),
})
