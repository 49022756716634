import { ChangeEvent, useEffect, useState } from 'react'

import { useParams } from '@tanstack/react-router'
import { useDebounce } from '@uidotdev/usehooks'
import { isPast } from 'date-fns'
import {
  BuildingIcon,
  ClockIcon,
  Loader2,
  MoreVertical,
  PhoneIcon,
  TriangleAlertIcon,
  VideoIcon,
  ZapIcon,
} from 'lucide-react'
import { toast } from 'sonner'

import { Button } from '@/components/ui/button'
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from '@/components/ui/dropdown-menu'
import { Text } from '@/components/ui/text'
import { Textarea } from '@/components/ui/textarea'
import { cn } from '@/lib/utils'
import { useCancelInterview, useFinishInterview, useUpdateInterviewsNote } from '@/queries/use-interviews-mutations'
import { useFormNavigationStore } from '@/store/formNavigationStore'
import { InterviewApplication } from '@/types/application/interview'

const TYPE_ICONS = {
  PHONE: PhoneIcon,
  PHYSICAL: BuildingIcon,
  VISIO: VideoIcon,
}

export function Interview({
  interview,
  isCancelOrRescheduled,
}: {
  interview: InterviewApplication['interviews'][0]
  isCancelOrRescheduled?: boolean
}) {
  const [value, setValue] = useState(interview.note || '')
  const { applicationId } = useParams({ from: '/_authenticated/jobs_/$jobId/applications/$applicationId' })
  const { isPending: isPendingUpdate, mutate: updateInterviewNote } = useUpdateInterviewsNote(applicationId)
  const { isPending: isPendingFinishInterview, mutate: finishInterview } = useFinishInterview(applicationId)
  const { mutate: cancelInterview } = useCancelInterview(applicationId)
  const { addDirtyForm, isFormDirty, removeDirtyForm } = useFormNavigationStore()

  const debouncedValue = useDebounce(value, 1000)

  useEffect(() => {
    if (debouncedValue !== interview.note && interview.status !== 'DONE') {
      updateInterviewNote({ body: { note: value }, interviewId: interview.id })
    }
  }, [debouncedValue, interview.id, interview.note, interview.status, updateInterviewNote, value])

  const handleUpdateNote = () => {
    if (interview.status === 'DONE') {
      updateInterviewNote(
        { body: { note: value }, interviewId: interview.id },
        {
          onSuccess: () => {
            toast.success('Note updated successfully.')
            removeDirtyForm(interview.id)
          },
        },
      )
      return
    }
    finishInterview({ interviewId: interview.id })
  }

  const handleCancelInterview = () => {
    cancelInterview(interview.id)
  }

  const handleUpdate = (e: ChangeEvent<HTMLTextAreaElement>) => {
    if (interview.status === 'DONE' && !isFormDirty(interview.id)) {
      addDirtyForm(interview.id)
    }
    setValue(e.target.value)
  }

  const TypeIcon = TYPE_ICONS[interview.type as keyof typeof TYPE_ICONS]
  const typeLabel = () => {
    switch (interview.type) {
      case 'PHONE':
        return 'Phone call'
      case 'PHYSICAL':
        return interview.location
      case 'VISIO':
        return interview.videoService.replace('_', ' ').toLowerCase()
      default:
        return 'Unknown'
    }
  }

  const showDropdown = interview.status !== 'DONE' && interview.status !== 'DIRECT_CALL' && !isCancelOrRescheduled
  const isButtonEnabled = interview.status !== 'TO_SCHEDULE' && isPast(interview.scheduledAt)
  const isDirectCallStatus = interview.status === 'DIRECT_CALL'
  const isDoneStatus = interview.status === 'DONE'

  return (
    <div className="flex flex-col gap-y-2 rounded border bg-background p-2">
      <div className="flex h-10 w-full items-center justify-between gap-x-6 bg-muted pl-2">
        <div className="shrink-0">
          <Text size="sm" weight="semibold">
            Note - {interview.stageName} {isDirectCallStatus && '(now)'}
          </Text>
        </div>
        <div className={cn('flex items-center gap-x-2 truncate', !showDropdown && 'pr-2')}>
          {isDirectCallStatus && (
            <div className="flex items-center gap-x-1 pr-2">
              <Text size="xs">Instant call</Text>
              <ZapIcon size={16} />
            </div>
          )}
          {!isDirectCallStatus && (
            <>
              <div className="flex gap-x-1 truncate">
                <TypeIcon size={16} />
                <Text capitalize size="xs" truncate>
                  {typeLabel()}
                </Text>
              </div>
              -
              <div className="flex shrink-0 gap-x-1">
                <ClockIcon size={16} />
                <Text size="xs">{interview.duration} min</Text>
              </div>
            </>
          )}
          {isPendingUpdate && (
            <Loader2
              className={cn('animate-spin text-muted-foreground', interview.status === 'DONE' && 'mr-2')}
              size={16}
            />
          )}
          {showDropdown && (
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <Button size="icon" variant="ghost">
                  <MoreVertical size={16} />
                </Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent>
                <DropdownMenuItem className="text-destructive focus:text-destructive" onClick={handleCancelInterview}>
                  Cancel
                </DropdownMenuItem>
              </DropdownMenuContent>
            </DropdownMenu>
          )}
        </div>
      </div>
      <div className="flex flex-col items-center gap-y-2">
        <Textarea
          className="min-h-64 pr-10"
          disabled={isCancelOrRescheduled}
          onChange={handleUpdate}
          placeholder="Add your note..."
          value={value}
        />
        {isFormDirty(interview.id) && (
          <div className="flex items-center gap-x-1">
            <TriangleAlertIcon className="size-4 text-destructive" />
            <Text size="sm" variant="red">
              changes not saved.
            </Text>
          </div>
        )}
        <Button
          disabled={!isButtonEnabled || !value.length || isCancelOrRescheduled}
          isLoading={isPendingUpdate || isPendingFinishInterview}
          onClick={handleUpdateNote}
        >
          {isDoneStatus ? 'Update note' : 'Finish interview'}
        </Button>
      </div>
    </div>
  )
}
