import { useQuery } from '@tanstack/react-query'
import { MenuPosition } from 'react-select'

import { Select } from '@/components/select'
import { configQuery } from '@/queries/use-config-queries'
import { Config } from '@/types/config'

export type ConfigType =
  | 'assetTypes'
  | 'automationsFields'
  | 'contractTypes'
  | 'interviewTimeStatuses'
  | 'interviewTypes'
  | 'jobRotations'
  | 'jobStatuses'
  | 'languageLevels'
  | 'officeJobFrequencies'
  | 'offshoreJobFrequencies'
  | 'onshoreJobFrequencies'
  | 'salaryCurrencies'
  | 'salaryPeriods'
  | 'seniorities'
  | 'softwareLevels'
  | 'sources'
  | 'taskPriorities'
  | 'taskStatuses'
  | 'typeOfSources'
  | 'videoServices'

const PLACEHOLDERS = {
  assetTypes: 'Select asset type...',
  automationsFields: 'Select field...',
  contractTypes: 'Select contract type...',
  interviewTimeStatuses: 'Select time...',
  interviewTypes: 'Select interview type...',
  jobRotations: 'Select rotation...',
  jobStatuses: 'Select status...',
  languageLevels: 'Select language level...',
  officeJobFrequencies: 'Select frequency...',
  offshoreJobFrequencies: 'Select frequency...',
  onshoreJobFrequencies: 'Select frequency...',
  salaryCurrencies: 'Select currency...',
  salaryPeriods: 'Select period...',
  seniorities: 'Select seniority...',
  softwareLevels: 'Select software level...',
  sources: 'Select source...',
  taskPriorities: 'Select priority...',
  taskStatuses: 'Select status...',
  typeOfSources: 'Select type of source...',
  videoServices: 'Select video service...',
}

export const ConfigSelect = ({
  disabled,
  menuPosition,
  onChange,
  placeholder,
  type,
  value,
}: {
  placeholder?: string
  value?: string | null
  onChange: (value: string) => void
  type: keyof Pick<Config, ConfigType>
  disabled?: boolean
  menuPosition?: MenuPosition
}) => {
  const { data: config, isLoading } = useQuery(configQuery)
  const defaultPlaceholder = placeholder ?? PLACEHOLDERS[type]

  const options = config
    ? Object.entries(config[type]).map(([value, label]) => ({
        label,
        value,
      }))
    : undefined

  return (
    <Select
      isDisabled={disabled}
      isLoading={isLoading}
      menuPosition={menuPosition}
      onChange={(value) => value && onChange(value.value)}
      options={options}
      placeholder={defaultPlaceholder}
      value={options?.find((opt) => opt.value === value)}
    />
  )
}
