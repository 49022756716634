import { AvailabilityStatus } from '@/shared/domain/enums/availability/availability-status.enum'
import { UpdateJobPreferencesData } from '@/shared/domain/schemas/update-job-preferences.schema'
import { JobPreferences } from '@/types/application/applicant-info'

type ContractType = keyof UpdateJobPreferencesData['salaryExpectations']

function transformSalary(
  salary: UpdateJobPreferencesData['salaryExpectations'],
): UpdateJobPreferencesData['salaryExpectations'] {
  const result: UpdateJobPreferencesData['salaryExpectations'] = { ...salary }

  ;(Object.keys(result) as ContractType[]).forEach((key) => {
    const value = result[key]

    if (value?.enabled === false) {
      result[key] = null
    } else if (value?.enabled === true) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { enabled, ...rest } = value
      result[key] = rest
    }
  })

  return result
}

const convertAvailabilityToDto = ({
  availability,
  availabilityDelay,
  availabilityPeriod,
  availabilityStatus,
}: UpdateJobPreferencesData): Pick<
  UpdateJobPreferencesData,
  'availability' | 'availabilityDelay' | 'availabilityPeriod' | 'availabilityStatus'
> => {
  if (availabilityStatus === AvailabilityStatus.KNOW_DATE) {
    return {
      availability,
      availabilityDelay: null,
      availabilityPeriod: null,
      availabilityStatus,
    }
  }

  return {
    availability: null,
    availabilityDelay,
    availabilityPeriod,
    availabilityStatus,
  }
}

export const convertJobPreferencesToDto = (preferences: UpdateJobPreferencesData): UpdateJobPreferencesData => {
  return {
    ...convertAvailabilityToDto(preferences),
    salaryExpectations: transformSalary(preferences.salaryExpectations),
  }
}

const formatSalaryExpectations = (
  salaryExpectations: JobPreferences['salaryExpectations'],
): UpdateJobPreferencesData['salaryExpectations'] => {
  return {
    apprenticeship: salaryExpectations?.apprenticeship ? { ...salaryExpectations.apprenticeship, enabled: true } : null,
    fixedTerm: salaryExpectations?.fixedTerm ? { ...salaryExpectations.fixedTerm, enabled: true } : null,
    freelance: salaryExpectations?.freelance ? { ...salaryExpectations.freelance, enabled: true } : null,
    internship: salaryExpectations?.internship ? { ...salaryExpectations.internship, enabled: true } : null,
    permanent: salaryExpectations?.permanent ? { ...salaryExpectations.permanent, enabled: true } : null,
  }
}

export const formatJobPreferences = (preferences: JobPreferences): UpdateJobPreferencesData => {
  return {
    availability: preferences.availability ? new Date(preferences.availability) : null,
    availabilityDelay: preferences.availabilityDelay ?? 0,
    availabilityPeriod: preferences.availabilityPeriod,
    availabilityStatus: preferences.availabilityStatus ?? AvailabilityStatus.KNOW_DATE,
    salaryExpectations: formatSalaryExpectations(preferences.salaryExpectations),
  }
}
