import { ReactNode } from 'react'

import {
  AtSignIcon,
  BotIcon,
  BriefcaseBusinessIcon,
  LucideIcon,
  MapPinIcon,
  PhoneIcon,
  SendIcon,
  UserSearchIcon,
} from 'lucide-react'

import { CountryFlag } from '@/components/country-flag'
import { LinkedinLink } from '@/components/linkedin-link'
import { Avatar, AvatarFallback } from '@/components/ui/avatar'
import { Text } from '@/components/ui/text'
import { Title } from '@/components/ui/title'
import { ApplicationApplicant } from '@/types/application/applicant-info'
import { getFormattedPhoneNumber } from '@/utils/phone'
import { formatApplicantLocation, getFullName, getFullNameInitial, getJobName } from '@/utils/string'

const TYPE_OF_SOURCE_MAPPING = {
  DIRECT_APPLY: {
    icon: SendIcon,
    label: 'Direct Apply',
  },
  SOURCING: {
    icon: UserSearchIcon,
    label: 'Sourcing',
  },
  SUGGESTED: {
    icon: BotIcon,
    label: 'Suggested',
  },
}

export const ApplicantPersonalInformation = ({
  applicant,
  children,
}: {
  applicant: ApplicationApplicant
  children?: ReactNode
}) => {
  const typeOfSource = applicant.typeOfSource
    ? TYPE_OF_SOURCE_MAPPING[applicant.typeOfSource as keyof typeof TYPE_OF_SOURCE_MAPPING]
    : undefined

  const TypeOfSourceIcon = typeOfSource?.icon
  const typeOfSourceLabel = typeOfSource?.label

  return (
    <div className="flex gap-x-4 rounded border bg-white px-8 py-4">
      <Avatar size="xl">
        <AvatarFallback>{getFullNameInitial(applicant)}</AvatarFallback>
      </Avatar>

      <div className="flex w-full flex-col gap-y-1">
        <div className="flex items-center gap-x-2">
          <Title level={3}>{getFullName(applicant)}</Title>
          <CountryFlag code={applicant.nationality.id} name={applicant.nationality.name} side="bottom" size="lg" />
          {applicant.linkedinUrl && <LinkedinLink size="lg" url={applicant.linkedinUrl} />}
        </div>
        <PersonalInformationItem icon={AtSignIcon} label={applicant.email || '-'} />
        <PersonalInformationItem icon={PhoneIcon} label={getFormattedPhoneNumber(applicant.phone)} />
        <PersonalInformationItem icon={BriefcaseBusinessIcon} label={getJobName(applicant)} />
        <PersonalInformationItem icon={MapPinIcon} label={formatApplicantLocation(applicant)} />
        {TypeOfSourceIcon && typeOfSourceLabel && (
          <PersonalInformationItem icon={TypeOfSourceIcon} label={typeOfSourceLabel} />
        )}
      </div>

      {children}
    </div>
  )
}

const PersonalInformationItem = ({ icon: Icon, label }: { label: string; icon: LucideIcon }) => {
  return (
    <div className="flex items-center gap-x-1">
      <Icon className="shrink-0" size={12} />
      <Text size="sm" weight="light">
        {label}
      </Text>
    </div>
  )
}
