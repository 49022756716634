import { useBlocker } from '@tanstack/react-router'

import { useFormNavigationStore } from '@/store/formNavigationStore'

export function useFormNavigation() {
  const { dirtyForms, setModalOpen } = useFormNavigationStore()

  return useBlocker({
    enableBeforeUnload: false,
    shouldBlockFn: () => {
      if (dirtyForms.size === 0) {
        return false
      }

      setModalOpen(true)
      return true
    },
    withResolver: true,
  })
}
