import { Control } from 'react-hook-form'

import { CustomFormField, FormFieldType } from '@/components/custom-form-field'
import { ConfigSelect } from '@/components/form/config-select'
import { UpdateJobPreferencesData } from '@/shared/domain/schemas/update-job-preferences.schema'

export const SalaryExpectationsFields = ({
  control,
  field,
}: {
  control: Control<UpdateJobPreferencesData>
  field: keyof UpdateJobPreferencesData['salaryExpectations']
}) => {
  return (
    <div className="grid grid-cols-4 gap-x-2">
      <CustomFormField
        control={control}
        fieldType={FormFieldType.NUMBER_INPUT}
        name={`salaryExpectations.${field}.min`}
        placeholder="min"
      />
      <CustomFormField
        control={control}
        fieldType={FormFieldType.NUMBER_INPUT}
        name={`salaryExpectations.${field}.max`}
        placeholder="max"
      />
      <CustomFormField
        control={control}
        fieldType={FormFieldType.SKELETON}
        name={`salaryExpectations.${field}.currency`}
        placeholder="Select currency..."
        renderSkeleton={(field) => (
          <ConfigSelect onChange={field.onChange} type="salaryCurrencies" value={field.value} />
        )}
      />
      <CustomFormField
        control={control}
        fieldType={FormFieldType.SKELETON}
        name={`salaryExpectations.${field}.period`}
        placeholder="Select period..."
        renderSkeleton={(field) => <ConfigSelect onChange={field.onChange} type="salaryPeriods" value={field.value} />}
      />
    </div>
  )
}
