import { queryOptions, useMutation, useQuery } from '@tanstack/react-query'

import { ApiGateway } from '@/modules/api/api.gateway'
import { JobRepository } from '@/modules/job/adapter/job.repository'
import { JobUseCase } from '@/modules/job/application/job.use-case'
import {
  JobInternalInformationsResponseDto,
  UpdateJobInternalInformationsRequestDto,
  UpdateStatusRequestDto,
} from '@/modules/job/domain/entities/job.entity'

const apiGateway = new ApiGateway()
const jobRepository = new JobRepository(apiGateway)
const jobUseCase = new JobUseCase(jobRepository)

const searchJobsQueryOptions = (query: string) =>
  queryOptions({
    enabled: !!query,
    queryFn: () => jobUseCase.search(query),
    queryKey: ['jobs/search', query],
  })

const useSearchJobs = (query: string) => {
  return useQuery(searchJobsQueryOptions(query))
}

const jobsStagesQueryOptions = (jobId?: string) =>
  queryOptions({
    enabled: !!jobId,
    queryFn: () => jobUseCase.getStages(jobId!),
    queryKey: ['jobs/stages', jobId],
  })

const useJobsStages = (jobId?: string) => {
  return useQuery(jobsStagesQueryOptions(jobId))
}

const jobsInternalInformationsQueryOptions = (jobId: string) =>
  queryOptions({
    queryFn: () => jobUseCase.getInternalInformations(jobId),
    queryKey: ['jobs/internal-informations', jobId],
  })

const useJobsInternalInformations = <T>({
  jobId,
  select,
}: {
  jobId: string
  select?: (data: JobInternalInformationsResponseDto) => T
}) => {
  return useQuery({ ...jobsInternalInformationsQueryOptions(jobId), select })
}

const useUpdateJobsInternalInformations = () => {
  return useMutation({
    mutationFn: ({ data, jobId }: { jobId: string; data: Partial<UpdateJobInternalInformationsRequestDto> }) =>
      jobUseCase.updateInternalInformations(jobId, data),
  })
}

const useUpdateJobsStatus = () => {
  return useMutation({
    mutationFn: ({ data, jobId }: { jobId: string; data: UpdateStatusRequestDto }) =>
      jobUseCase.updateStatus(jobId, data),
  })
}

export {
  useSearchJobs,
  useJobsStages,
  useJobsInternalInformations,
  useUpdateJobsInternalInformations,
  useUpdateJobsStatus,
}
