import { Control, useFormContext } from 'react-hook-form'

import { Checkbox } from '@/components/ui/checkbox'
import { Label } from '@/components/ui/label'
import { UpdateJobPreferencesData } from '@/shared/domain/schemas/update-job-preferences.schema'

import { SalaryExpectationsFields } from './SalaryExpectationsFields'

const SALARY_EXPECTATION_NAMES_MAPPING = {
  apprenticeship: 'Apprenticeship',
  fixedTerm: 'Fixed-term',
  freelance: 'Freelance',
  internship: 'Internship',
  permanent: 'Permanent',
}

export const SalaryExpectationsItem = ({
  control,
  field,
}: {
  control: Control<UpdateJobPreferencesData>
  field: keyof UpdateJobPreferencesData['salaryExpectations']
}) => {
  const { setValue, watch } = useFormContext<UpdateJobPreferencesData>()

  const watchEnabled = watch(`salaryExpectations.${field}.enabled`)

  return (
    <div className="space-y-2">
      <div className="flex items-center space-x-2">
        <Checkbox
          checked={watchEnabled}
          id={`${field}.enabled`}
          onCheckedChange={(checked) => setValue(`salaryExpectations.${field}.enabled`, checked as boolean)}
        />
        <Label htmlFor={`${field}.enabled`}>{SALARY_EXPECTATION_NAMES_MAPPING[field]}</Label>
      </div>
      {watchEnabled && <SalaryExpectationsFields control={control} field={field} />}
    </div>
  )
}
