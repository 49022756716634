import {
  JobInternalInformationsResponseDto,
  JobSearchResponseDto,
  JobStagesResponseDto,
  UpdateJobInternalInformationsRequestDto,
  UpdateStatusRequestDto,
} from '@/modules/job/domain/entities/job.entity'
import { IJobRepository } from '@/modules/job/domain/repositories/job.repository'
import { IdResponseDto } from '@/shared/domain/entities/common.entity'

export class JobUseCase {
  constructor(private jobRepository: IJobRepository) {}

  async search(query: string): Promise<JobSearchResponseDto[]> {
    return this.jobRepository.search(query)
  }

  async getStages(jobId: string): Promise<JobStagesResponseDto> {
    return this.jobRepository.getStages(jobId)
  }

  async getInternalInformations(jobId: string): Promise<JobInternalInformationsResponseDto> {
    return this.jobRepository.getInternalInformations(jobId)
  }

  async updateInternalInformations(
    jobId: string,
    data: Partial<UpdateJobInternalInformationsRequestDto>,
  ): Promise<IdResponseDto> {
    return this.jobRepository.updateInternalInformations(jobId, data)
  }

  async updateStatus(jobId: string, data: UpdateStatusRequestDto): Promise<IdResponseDto> {
    return this.jobRepository.updateStatus(jobId, data)
  }
}
