import { createFileRoute } from '@tanstack/react-router'

import { PageContent } from '@/components/page-content'
import { PageMenu } from '@/components/page-menu'
import { Skeleton } from '@/components/ui/skeleton'
import { jobFilterSchema } from '@/schemas/job'

export const Route = createFileRoute('/_authenticated/jobs')({
  beforeLoad: () => {
    return {
      meta: {
        title: 'Jobs',
      },
    }
  },
  loaderDeps: ({ search: { organizationIds, page, recruiterIds, statuses } }) => ({
    organizationIds,
    page,
    recruiterIds,
    statuses,
  }),
  pendingComponent: () => (
    <>
      <PageMenu>
        <div className="grid w-full grid-cols-3">
          <Skeleton className="h-9 w-28" />
          <Skeleton className="h-9 w-full" />
          <Skeleton className="ml-auto h-9 w-28" />
        </div>
      </PageMenu>
      <PageContent>
        <div className="flex flex-col gap-y-8">
          <div className="grid grid-cols-1 gap-8 lg:grid-cols-2">
            <Skeleton className="h-44 w-full" />
            <Skeleton className="h-44 w-full" />
          </div>
          <div className="grid grid-cols-1 gap-8 lg:grid-cols-2">
            <Skeleton className="h-44 w-full" />
            <Skeleton className="h-44 w-full" />
          </div>
        </div>
      </PageContent>
    </>
  ),
  validateSearch: jobFilterSchema,
})
