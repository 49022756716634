import { create } from 'zustand'

interface FormNavigationStore {
  dirtyForms: Set<string>
  modalOpen: boolean
  addDirtyForm: (formId: string) => void
  removeDirtyForm: (formId: string) => void
  resetAllForms: () => void
  setModalOpen: (isOpen: boolean) => void
  isFormDirty: (formId: string) => boolean
  getDirtyFormsCount: () => number
}

export const useFormNavigationStore = create<FormNavigationStore>((set, get) => ({
  addDirtyForm: (formId: string) =>
    set((state) => ({
      dirtyForms: new Set([...state.dirtyForms, formId]),
    })),
  dirtyForms: new Set<string>(),
  getDirtyFormsCount: () => get().dirtyForms.size,
  isFormDirty: (formId: string) => get().dirtyForms.has(formId),
  modalOpen: false,
  removeDirtyForm: (formId: string) =>
    set((state) => {
      const newSet = new Set(state.dirtyForms)
      newSet.delete(formId)
      return { dirtyForms: newSet }
    }),

  resetAllForms: () => set({ dirtyForms: new Set() }),
  setModalOpen: (isOpen: boolean) => set({ modalOpen: isOpen }),
}))
