import { Control } from 'react-hook-form'

import { CustomFormField, FormFieldType } from '@/components/custom-form-field'
import { PeriodSelect } from '@/components/form/selects/PeriodSelect'
import { FormControl, FormField, FormItem, FormLabel, FormMessage } from '@/components/ui/form'
import { RadioGroupItem, RadioGroup } from '@/components/ui/radio-group'
import { AvailabilityStatus } from '@/shared/domain/enums/availability/availability-status.enum'
import { UpdateJobPreferencesData } from '@/shared/domain/schemas/update-job-preferences.schema'
import { isBeforeToday } from '@/utils/date'

export const AvailabilityFields = ({ control }: { control: Control<UpdateJobPreferencesData> }) => {
  return (
    <FormField
      control={control}
      name="availabilityStatus"
      render={({ field }) => (
        <FormItem>
          <FormControl>
            <RadioGroup className="flex flex-col space-y-2" onValueChange={field.onChange} value={field.value}>
              <div className="space-y-4">
                <FormItem className="flex flex-row items-center gap-x-2">
                  <FormControl>
                    <RadioGroupItem value={AvailabilityStatus.KNOW_DATE} />
                  </FormControl>
                  <FormLabel>Available date</FormLabel>
                </FormItem>
                <div className="pl-5">
                  <CustomFormField
                    control={control}
                    disabledDate={isBeforeToday}
                    fieldType={FormFieldType.DATE_PICKER}
                    name="availability"
                    startMonth={new Date()}
                  />
                </div>
              </div>
              <div className="space-y-4">
                <FormItem className="flex flex-row items-center gap-x-2">
                  <FormControl>
                    <RadioGroupItem value={AvailabilityStatus.NOTICE_PERIOD} />
                  </FormControl>
                  <FormLabel>Notice period</FormLabel>
                </FormItem>
                <div className="grid grid-cols-2 gap-4 pl-5">
                  <CustomFormField
                    control={control}
                    fieldType={FormFieldType.NUMBER_INPUT}
                    name="availabilityDelay"
                    placeholder="Select delay"
                  />
                  <CustomFormField
                    control={control}
                    fieldType={FormFieldType.SKELETON}
                    name="availabilityPeriod"
                    placeholder="Select period"
                    renderSkeleton={(field) => <PeriodSelect onChange={field.onChange} value={field.value} />}
                  />
                </div>
              </div>
            </RadioGroup>
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    />
  )
}
