import { UseMutationResult } from '@tanstack/react-query'

import { Button } from '@/components/ui/button'
import { Form } from '@/components/ui/form'
import { Title } from '@/components/ui/title'
import { UpdateJobPreferencesData } from '@/shared/domain/schemas/update-job-preferences.schema'
import { JobPreferences } from '@/types/application/applicant-info'

import { AvailabilityFields } from './components/AvailabilityFields'
import { SalaryExpectationsItem } from './components/SalararyExpectationsItem'
import { useApplicantJobPreferences } from './hooks/useApplicantJobPreferences'

export const ApplicantJobPreferences = ({
  mutation,
  preferences,
}: {
  preferences: JobPreferences
  mutation: UseMutationResult<JobPreferences, Error, UpdateJobPreferencesData, unknown>
}) => {
  const { form, onSubmit, salaryExpectationsFields } = useApplicantJobPreferences({
    defaultValues: preferences,
    mutation,
  })

  return (
    <Form {...form}>
      <form className="space-y-8" onSubmit={onSubmit}>
        <div className="space-y-4">
          <Title as="h4" level={6}>
            Availability
          </Title>
          <AvailabilityFields control={form.control} />
        </div>
        <div className="space-y-4">
          <Title as="h4" level={6}>
            Contract & salary expectations
          </Title>

          {salaryExpectationsFields.map((field) => (
            <SalaryExpectationsItem control={form.control} field={field} key={field} />
          ))}
        </div>

        <div className="text-right">
          <Button type="submit">Update</Button>
        </div>
      </form>
    </Form>
  )
}
