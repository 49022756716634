import {
  UserSearch,
  User,
  UserPersonalData,
  PaginatedUserCommentResponse,
} from '@/modules/user/domain/entities/user.entity'
import { IUserRepository } from '@/modules/user/domain/repositories/user.repository'
import { CheckEmailDto, UserCheckEmailDto } from '@/modules/user/domain/schemas/check-email'
import { CreateCommentDto } from '@/modules/user/domain/schemas/create-comment.schema.ts'
import { CreateUserApplicationDto } from '@/modules/user/domain/schemas/create-user-application.schema.ts'
import { UpdatePersonalDataValues } from '@/modules/user/domain/schemas/update-personal-data.schema'
import { UploadResumeDto } from '@/modules/user/domain/schemas/upload-resume.schema.ts'
import { IdResponseDto } from '@/shared/domain/entities/common.entity.ts'
import { PaginationDto } from '@/shared/domain/entities/pagination.entity'
import { UpdateJobPreferencesData } from '@/shared/domain/schemas/update-job-preferences.schema'
import { JobPreferences } from '@/types/application/applicant-info'

export class UserUseCase {
  constructor(private userRepository: IUserRepository) {}

  async search(query: string): Promise<UserSearch[]> {
    return this.userRepository.search(query)
  }

  async get(id: string): Promise<User> {
    return this.userRepository.get(id)
  }

  async getPersonalData(id: string): Promise<UserPersonalData> {
    return this.userRepository.getPersonalData(id)
  }

  async updatePersonalData(id: string, data: UpdatePersonalDataValues): Promise<IdResponseDto> {
    return this.userRepository.updatePersonalData(id, data)
  }

  async checkEmail(data: CheckEmailDto): Promise<UserCheckEmailDto> {
    return this.userRepository.checkEmail(data)
  }

  async createApplication(data: CreateUserApplicationDto): Promise<IdResponseDto> {
    return this.userRepository.createApplication(data)
  }

  async uploadResume(data: FormData): Promise<UploadResumeDto> {
    return this.userRepository.uploadResume(data)
  }

  async getUserComments(userId: string, pagination: PaginationDto): Promise<PaginatedUserCommentResponse> {
    return this.userRepository.getUserComments(userId, pagination)
  }

  async createUserComment(userId: string, data: CreateCommentDto): Promise<IdResponseDto> {
    return this.userRepository.createUserComment(userId, data)
  }

  async updateJobPreferences(id: string, data: UpdateJobPreferencesData): Promise<JobPreferences> {
    return this.userRepository.updateJobPreferences(id, data)
  }
}
