import { Select } from '@/components/select'
import { AvailabilityPeriodLong } from '@/shared/domain/enums/availability/availability-period.enum'
import { capitalizeFirstLetter } from '@/utils/string'

const options = Object.entries(AvailabilityPeriodLong).map(([value, label]) => ({
  label: capitalizeFirstLetter(label),
  value,
}))

export const PeriodSelect = ({
  onChange,
  placeholder = 'Select period...',
  value,
}: {
  onChange: (value: string) => void
  placeholder?: string
  value?: string | null
}) => {
  return (
    <Select
      menuPosition="absolute"
      onChange={(value) => value && onChange(value?.value)}
      options={options}
      placeholder={placeholder}
      value={options.find((opt) => opt.value === value) || null}
    />
  )
}
