import { ApiGateway } from '@/modules/api/api.gateway'
import {
  UserSearch,
  User,
  UserPersonalData,
  PaginatedUserCommentResponse,
} from '@/modules/user/domain/entities/user.entity'
import { IUserRepository } from '@/modules/user/domain/repositories/user.repository'
import { CheckEmailDto, UserCheckEmailDto } from '@/modules/user/domain/schemas/check-email'
import { CreateCommentDto } from '@/modules/user/domain/schemas/create-comment.schema'
import { CreateUserApplicationDto } from '@/modules/user/domain/schemas/create-user-application.schema'
import { UpdatePersonalDataValues } from '@/modules/user/domain/schemas/update-personal-data.schema'
import { UploadResumeDto } from '@/modules/user/domain/schemas/upload-resume.schema'
import { IdResponseDto } from '@/shared/domain/entities/common.entity'
import { PaginationDto } from '@/shared/domain/entities/pagination.entity'
import { UpdateJobPreferencesData } from '@/shared/domain/schemas/update-job-preferences.schema'
import { JobPreferences } from '@/types/application/applicant-info'

export class UserRepository implements IUserRepository {
  constructor(private api: ApiGateway) {}

  async search(query: string): Promise<UserSearch[]> {
    return this.api.get<UserSearch[]>(`/users/search?q=${query}`)
  }

  async get(id: string): Promise<User> {
    return this.api.get<User>(`/users/${id}`)
  }

  async getPersonalData(id: string): Promise<UserPersonalData> {
    return this.api.get<UserPersonalData>(`/users/personal-data/${id}`)
  }

  async updatePersonalData(id: string, data: UpdatePersonalDataValues): Promise<IdResponseDto> {
    return this.api.put<IdResponseDto, UpdatePersonalDataValues>(`/users/personal-data/${id}`, data)
  }

  async createApplication(data: CreateUserApplicationDto): Promise<IdResponseDto> {
    return this.api.post<IdResponseDto, CreateUserApplicationDto>('/users/application', data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
  }

  async checkEmail(data: CheckEmailDto): Promise<UserCheckEmailDto> {
    return this.api.post<UserCheckEmailDto, CheckEmailDto>('/users/check-email', data, {
      customErrorHandling: true,
    })
  }

  async uploadResume(data: FormData): Promise<UploadResumeDto> {
    return this.api.post<UploadResumeDto, FormData>('/users/resume', data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
  }

  async getUserComments(userId: string, pagination: PaginationDto): Promise<PaginatedUserCommentResponse> {
    return this.api.get<PaginatedUserCommentResponse>(`user-comments/${userId}`, {
      params: pagination,
    })
  }

  async createUserComment(userId: string, data: CreateCommentDto): Promise<IdResponseDto> {
    return this.api.post<IdResponseDto, CreateCommentDto>(`user-comments/${userId}`, data)
  }

  async updateJobPreferences(id: string, data: UpdateJobPreferencesData): Promise<JobPreferences> {
    return this.api.put<JobPreferences, UpdateJobPreferencesData>(`/users/${id}/job-preferences`, data)
  }
}
