import { createFileRoute } from '@tanstack/react-router'

import { objectiveFiltersSchema } from '@/modules/objective/domain/schemas/filter.schema'

export const Route = createFileRoute('/_authenticated/objectives/')({
  beforeLoad: () => {
    return {
      meta: {
        title: 'Objectives',
      },
    }
  },
  validateSearch: objectiveFiltersSchema,
})
