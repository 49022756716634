import { createFileRoute } from '@tanstack/react-router'

import { PageMenu } from '@/components/page-menu'

export const Route = createFileRoute('/_authenticated/applicants/')({
  beforeLoad: () => {
    return {
      meta: {
        title: 'Applicants',
      },
    }
  },
  pendingComponent: () => (
    <>
      <PageMenu>
        <div className="grid w-full grid-cols-3">
          <div className="h-9 w-28" />
          <div className="h-9 w-full" />
          <div className="ml-auto h-9 w-28" />
        </div>
      </PageMenu>
    </>
  ),
})
